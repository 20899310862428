/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, TitleMain, Subtitle, Text, Title } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O infratopení"}>
        <ColumnCover name={"g5a9uwv0uq"}>
          
          <Column className="--menu pb--12 pt--12" anim={null} menu={true} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
            
            <Menu className="--full" fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box pl--06">
                
                <Image className="--left" alt={"Logo PROFICOMFORT – infratopení"} src={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png"} svg={false} url={"https://proficomfort.cz"} use={"url"} href={"https://proficomfort.cz"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":240}} srcSet={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=860x_.png 860w"} target={null}>
                </Image>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"/"} target={""} content={"Úvod"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"/o-nas"} target={""} content={"O NÁS"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" url={"https://proficomfort.cz/infrapanely-do-domacnosti/"} use={"url"} href={"https://proficomfort.cz/infrapanely-do-domacnosti/"} target={""} content={"Infratopení"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"https://proficomfort.cz/#produkty"} target={""} content={"PRODUKTY"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"/ke-stazeni"} target={""} content={"KE STAŽENÍ"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01" innerClassName="pb--02 pl--0 pr--0" href={"/kontakt"} target={""} content={"KONTAKT"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="mt--60 pb--60 pt--60" name={"szq16opjo9n"} style={{"backgroundColor":"rgba(216,36,36,1)"}}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1440}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <TitleMain className="title-box w--900 title-box--invert" content={"O infratopení"}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--80" name={"szq16opjo9n"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1440}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box lh--16" content={"Infratopení se stalo během několika posledních let velice žádaným artiklem. Hlavní výhodou vytápění pomocí <a href=\"/infrapanely-do-domacnosti\">infrapanelů</a> je cenová dostupnost a bezúdržbový způsob vytápění. V tom je hlavní rozdíl mezi klasickým topením a vytápěním pomocí infra topných panelů.\n\n"}>
              </Subtitle>

              <Text className="text-box fs--18 w--500 lh--2" content={"<span style=\"color: rgb(78, 80, 97);\">Infračervené topné panely prohřívají pevné předměty (podlahy, stěny, stropy) do hloubky, od kterých se ohřeje vzduch v místnosti. Infračervené vytápění udrží v pevných materiálech mnohem déle teplo, stěny navíc zůstávají suché a nedochází k tvorbě plísní. Pouze minimální cirkulace vzduchu a malý rozdíl mezi podlahou a stropem nám zabezpečí příjemnou tepelnou pohodu a pouze minimum prašnosti. I proto jsou infračervené zářiče velmi vhodné pro alergiky, kteří jsou citlivý na prach a trpí dýchacími potížemi.\n\n</span><br>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2" content={"<span style=\"color: rgb(78, 80, 97);\">Již zmiňované zahřívání stěn při topení pomocí infrapanelů způsobuje nejenom následné ohřívání vzduchu, ale navíc stěny a zdivo vysušuje. <a href=\"/\">Infratopení</a> tak můžete zamezit jakémukoliv výskytu plísní, které vznikají hlavně při nedostatečném větrání.&nbsp;&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2" content={"<span style=\"color: rgb(78, 80, 97);\">Kvalitní infračervené zářiče mají výhodu v tom, že jsou schopny vydávat teplo v podstatě okamžitě. Navíc se jedná o sálavý druh vytápění, takže teplý vzduch necirkuluje v místnosti a drží se spíše ve spodní části prostor.\n\n</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5" anim={"4"} animS={"5"} style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-10i2o0w bg--center --parallax pb--80 pt--80" name={"31m9acmz1vl"} style={{"backgroundColor":"rgba(25, 24, 30, 1)"}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 mb--10 mt--10" style={{"maxWidth":1440}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --center pb--10" anim={"5"} animS={"5"} style={{"maxWidth":1440}}>
              
              <Title className="title-box title-box--invert" content={"Vyberte si z naši široké nabídky"}>
              </Title>

              <Button className="btn-box btn-box--hvr3 w--900 swpf--uppercase ls--0" href={"/"} content={"<span style=\"color: rgb(255, 255, 255);\">Prohlížet produkty</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--80" name={"szq16opjo9n"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" style={{"maxWidth":1440}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" content={"Výhody infratopení\n\n"}>
              </Subtitle>

              <Text className="text-box fs--18 w--500 lh--2" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; <span style=\"color: rgb(78, 80, 97);\">&nbsp;nízké pořizovací náklady v porovnání s běžnými topnými systémy&nbsp;</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">nízké montážní náklady infrapanelů</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">zdravotně nezávadné a ekologické vytápění</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">příjemný pocit sálavého tepla blahodárně působí na rostliny i lidskou psychiku</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">během vytápění infra zářiči dochází k vysušování zdiva</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">infrapanely nahřívají stěny (o 2°C vyšší teplota stěn)</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">relativní vlhkost vzduchu zůstane zachována</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">díky minimální cirkulaci vzduchu je infratopení vhodné i pro alergiky</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">jednoduché ovládání infrazářičů pomocí termostatu</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">úspora místa a velmi hezký design infrapanelů</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">levný provoz a získání levné sazby elektřiny</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">infrapanely PROFICOMFORT jsou bezúdržbové</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">možnost snadné kontroly spotřeby energie</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" content={"Pořizovací náklady infratopení&nbsp;&nbsp;"}>
              </Subtitle>

              <Text className="text-box fs--18 w--500 lh--2" content={"<span style=\"color: rgb(78, 80, 97);\">v porovnání s běžnými topnými systémy jsou velmi nízké. Pokud reálně srovnáme celkové náklady na běžné vytápění (nákup kotle, rozvodů, radiátorů či topných čerpadel) dokážeme se s  infračervenými topnými panely cenově dostat o více než 50% níže.</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box mt--40" content={"Zvýšený komfort"}>
              </Subtitle>

              <Text className="text-box fs--18 w--500 lh--2" content={"<span style=\"color: rgb(78, 80, 97);\">Při nízké teplotě prostoru není tepelná pohoda člověka závislá jenom na teplotě okolního vzduchu, ale také na teplotě povrchů všech okolních ploch. Infrapanely prohřívají pevné podlahy, stěny i stropy, od kterých se ohřeje vzduch v místnosti.\n\n</span><br>"}>
              </Text>

              <Subtitle className="subtitle-box mt--40" content={"Bezpečnost infrapanelů"}>
              </Subtitle>

              <Text className="text-box fs--18 w--500 lh--2" content={"<span style=\"color: rgb(78, 80, 97);\">Infračervené sálání má blahodárné účinky pro organismus i pro pokojové rostliny. Vzduch není přepalován, zůstává čistý. Není proto zapotřebí zvlhčovat vzduch. Při topení infračervenými panely nedochází také k víření prachu, a tak je tento způsob vytápění vhodný i pro alergiky.\n</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5" anim={"4"} animS={"5"} style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l8w pb--60 pt--20" name={"ao7mel003yg"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/917fa18faf684771b53f4ad097757e69_s=350x_.jpg"} svg={false} ratio={null} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/917fa18faf684771b53f4ad097757e69_s=350x_.jpg 350w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11212/88ccd215c2a043e783026595340a9e73_s=350x_.jpg"} svg={false} ratio={null} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/88ccd215c2a043e783026595340a9e73_s=350x_.jpg 350w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1knc10f bg--center --parallax pb--60 pt--60" style={{"backgroundColor":"rgba(25, 24, 30, 1)"}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--3 mb--40 mt--25" style={{"maxWidth":1440}} columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Logo – infratopení – PROFICOMFORT"} src={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=860x_.png 860w"} content={null}>
              </Image>

              <Text className="text-box fs--18 w--600 text-box--invert mt--25" content={"<span style=\"color: rgb(127, 129, 150);\">Hosov 65<br>586 01 Jihlava</span><br>"}>
              </Text>

              <Text className="text-box fs--18 w--600 text-box--invert mt--10" content={"<span style=\"color: rgb(127, 129, 150);\">+420 776 117 775<br>nekula@proficomfort.cz</span><br>"}>
              </Text>

              <Image className="pl--06" alt={""} src={"https://cdn.swbpg.com/o/11212/44bad18f84e0428187e9d7bd02eaea99.svg"} svg={false} href={"https://www.facebook.com/proficomfort/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":27}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left pt--10" style={{"maxWidth":344}}>
              
              <Subtitle className="subtitle-box mb--30" content={"<span style=\"color: rgb(255, 255, 255);\">Produkty</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" url={"https://proficomfort.cz/infrapanely-do-domacnosti/"} use={"url"} href={"https://proficomfort.cz/infrapanely-do-domacnosti/"} content={"<span style=\"color: rgb(127, 129, 150);\">Infrapanely do domácnosti</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/elektricke-podlahove-topeni"} content={"<span style=\"color: rgb(127, 129, 150);\">Elektrické podlahové topení</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/termostaty-a-regulace"} content={"<span style=\"color: rgb(127, 129, 150);\">Termostaty a regulace</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/prumyslove-salave-zarice"} content={"<span style=\"color: rgb(127, 129, 150);\">Průmyslové sálavé zářiče</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/halogenove-zarice"} content={"<span style=\"color: rgb(127, 129, 150);\">Halogenové zářiče</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right pt--10" style={{"maxWidth":344}}>
              
              <Subtitle className="subtitle-box mb--30" content={"<span style=\"color: rgb(255, 255, 255);\">Navigace</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/"} content={"<span style=\"color: rgb(127, 129, 150);\">Úvod</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"https://proficomfort.cz/#produkty"} target={null} content={"<span style=\"color: rgb(127, 129, 150);\">Produkty</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/o-nas"} content={"<span style=\"color: rgb(127, 129, 150);\">O nás</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/ke-stazeni"} content={"<span style=\"color: rgb(127, 129, 150);\">Ke stažení</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/kontakt"} content={"<span style=\"color: rgb(127, 129, 150);\">Kontakt</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--10 pt--10" anim={null} animS={null} style={{"backgroundColor":"rgba(216,36,36,1)"}} border={null}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"maxWidth":1440}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--left fs--14 w--600 text-box--invert" content={"<a href=\"https://saywebpage.com/cs/\" style=\"color: rgb(255, 255, 255);\">Tvorba webových stránek</a>: saywebpage.com<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--right fs--18 w--600 text-box--invert" content={"2023<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}